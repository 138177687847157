<template>
    <el-main>
        <!-- 搜索 -->
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="订单编号：">
                <el-input size="small" placeholder="请输入订单编号" v-model="form.order_no"></el-input>
            </el-form-item>
            <el-form-item label="会员姓名：">
                <el-input size="small" placeholder="请输入会员姓名：" v-model="form.nickname"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
                <el-input size="small" placeholder="请输入联系电话：" v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item label="创建时间：">
                <el-date-picker value-format="timestamp" v-model="form.time" size="small" type="daterange"
                    range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button size="small" type="primary" @click="search">搜索</el-button>
                <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <!--  -->
        <el-tabs type="card" v-model="form.status" @tab-click="getList">
            <el-tab-pane :name="item.status" v-for="item in status_list" :key="item.status">
                <span slot="label">{{ item.title }}（{{ item.count }}）</span>
            </el-tab-pane>
        </el-tabs>
        <!-- 表格 -->
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" @selection-change="selectionChange"
            ref="table">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="order_no" label="订单编号" align="center" width="200"></el-table-column>
            <el-table-column prop="nickname" label="会员姓名" align="center" width="150"></el-table-column>
            <el-table-column prop="mobile" label="联系电话" align="center" width="150"></el-table-column>
            <el-table-column prop="classify_name" label="订阅时价" align="center" width="200">
                <template slot-scope="scope">
                    {{ (scope.row.type == 1 ? '自定义服务套餐' : scope.row.subscription_data[0].name) }}，{{ scope.row.day }}天
                    <el-popover placement="top-start" width="300" trigger="hover">
                        <div v-for="item in scope.row.subscription_data" :key="item.id" class="subscription">
                            <div class="subscription_name">{{ item.name }}</div>
                            <div class="subscription_classfy">
                                <div v-for="v in item.data" :key="v.id">{{ v.name }}</div>
                            </div>
                        </div>
                        <i class="el-icon-warning-outline primary" slot="reference" v-if="(scope.row.type == 1)"></i>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="total_price" label="时价价格" align="center"></el-table-column>
            <el-table-column prop="discount" label="会员折扣" align="center"></el-table-column>
            <el-table-column prop="discount_price" label="折后价格" align="center"></el-table-column>
            <el-table-column prop="amount" label="支付金额" width="140" align="center"></el-table-column>
            <el-table-column prop="pay_integral" label="支付积分" align="center"></el-table-column>
            <el-table-column prop="status" label="订单状态" align="center">
                <template slot-scope="scope">
                    <div :style="{ 'color': scope.row.status == 1 ? '#409EFF' : scope.row.status == 2 ? '#F56C6C' : '#E6A23C' }">
                        {{ status_list.find(item => item.status == scope.row.status).title }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="create_time" width="160" label="创建时间" align="center">
                <template slot-scope="scope">
                    <span>{{ getDateformat(scope.row.update_time) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" v-if="(scope.row.status == -1)" @click="cancelOrder(scope.row.id)">取消订单</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            form: {
                page: 1,
                row: 10,
                order_no: '',
                nickname: '',
                mobile: '',
                time: '',
                status: '0'
            },
            status_list: [],
            total: 0,
            list: [],
        };
    },
    created () {
        this.getStatus();
    },
    methods: {
        search () {
            this.form.page = 1;
            this.getList();
        },
        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                name: '',
                user: '',
                time: '',
                time2: '',
                tel: '',
                type: '',
            };
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        getStatus () {
            this.$axios
                .post(this.$api.gateway.currentprice.getStatus)
                .then(res => {
                    if (res.code == 0) {
                        this.status_list = res.result
                        this.getList()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getList () {
            let data = Object.assign({}, this.form)
            if (data.time) {
                data.start_time = data.time[0] / 1000
                data.end_time = data.time[1] / 1000
                delete data.time
            }
            this.$axios
                .post(this.$api.gateway.currentprice.orderList, data)
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result.list
                        list.forEach(item => {
                            item.subscription_data = JSON.parse(item.subscription_data)
                        });
                        this.list = res.result.list;
                        this.total = res.result.total_number;

                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getDateformat,
        cancelOrder (order_id) {
            this.$confirm('是否取消此订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$api.gateway.currentprice.cancelOrder, { order_id })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message({
                                type: 'success',
                                message: '取消成功!'
                            });
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            }).catch(() => { });
        }
    },
};
</script>

<style lang="less" scoped>
.el-table {
    border-left: 1px solid #e8eef1;
    border-right: 1px solid #e8eef1;

    /deep/ .el-input__inner {
        border-color: #fff;
    }

    /deep/ .el-input__inner:focus {
        border-color: #409eff;
    }
}

.el-main {
    background: #fff;
}

.primary {
    color: #409EFF;
    font-size: 18px;
    transform: translateY(2px);
}

.subscription {
    &+.subscription {
        margin-top: 20px;
    }

    .subscription_name {
        margin-bottom: 10px;
    }

    .subscription_classfy {
        display: grid;
        grid-template-columns: repeat(4, 60px);
        gap: 10px;
    }
}</style>

